import React, { Fragment, useMemo } from 'react';

import { SearchResultsRecommendedHotels } from './SearchResultsRecommendedHotels';
import { SeoContent } from './SeoContent';
import { SearchResultsComponent } from '@Components/LayoutComponents/SearchResultsComponent/SearchResultsComponent';
import Newsletter from '@Components/Newsletter/Newsletter';
import { BASIC_EMAIL_REGEX } from '@Core/emailRegex';
import { useQueryParams } from '@Core/helpers/url';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { ProductType } from '@Core/tracking/types';
import { useInitialSearchSelection } from '@Core/useInitialSearchSelection';
import { Hotels } from '@Pages/home/Hotels/Hotels';
import { Page } from '@Pages/Page';
import { SearchResultsStore } from '@Stores/SearchResultsStore';
import { StoreOverride } from '@Stores/StoreContext';

export default () => {
  const initialSearchSelection = useInitialSearchSelection();
  const isNewsletterEnabled = useFeatureFlag('NewsletterEnabled');
  const isGenericSearchResultsPageEnabled = useFeatureFlag('GenericSRPPanda');

  // Figure out store overrides based on URL
  const { missedIt, offerId, shortRef, productType } = useQueryParams();
  const initialValues: Partial<SearchResultsStore> = { offerId, shortRef };
  if (!!missedIt) {
    initialValues.missedIt = true;
  }
  if (!!productType) {
    initialValues.productType = productType as ProductType;
  }
  const jsonInitialSearchSelection = JSON.stringify(initialSearchSelection);

  return useMemo(() => {
    const content = (
      <Fragment>
        <SearchResultsComponent searchSelection={initialSearchSelection} />

        {!isGenericSearchResultsPageEnabled && (
          <Fragment>
            <SearchResultsRecommendedHotels />
            <Hotels
              sx={{ marginTop: 'xl', backgroundColor: 'transparent' }}
              items={[]}
            />
          </Fragment>
        )}

        {isNewsletterEnabled && !isGenericSearchResultsPageEnabled && (
          <Newsletter
            sx={{
              marginTop: '4xl',
            }}
            emailRegex={BASIC_EMAIL_REGEX}
          />
        )}

        {!isGenericSearchResultsPageEnabled && (
          <SeoContent
            sx={{
              marginTop: 'xl',
            }}
          />
        )}
      </Fragment>
    );

    return (
      <StoreOverride
        initialValues={{
          searchResults: initialValues,
        }}
      >
        {isGenericSearchResultsPageEnabled ? content : <Page pageType="srp">{content}</Page>}
      </StoreOverride>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonInitialSearchSelection, isNewsletterEnabled]);
};
