import { PageContainer } from '@loveholidays/design-system';
import React, { useMemo } from 'react';
import { useQuery } from 'urql';

import getSearchResultsPageContent from './getSearchResultsPageContent.gql';
import { Query } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { LandingBackgroundWrapper } from '@Components/LayoutComponents/LandingBackgroundWrapper';
import { LazyComponent } from '@Components/LayoutComponents/LazyComponent';
import { isQueryResolved } from '@Core/isQueryResolved';

export const SeoContent: React.FC<ClassNameProps> = ({ className }) => {
  const [{ data, fetching }] = useQuery<Query>({
    query: getSearchResultsPageContent,
  });

  if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  }

  const { seoContent } = data.Content.searchResultPage;

  if (!seoContent.length) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <LandingBackgroundWrapper>
        <PageContainer className={className}>
          <section>
            {seoContent.map(({ type, id, ...props }, index) => (
              <LazyComponent
                id={id}
                key={index}
                index={index}
                type={type}
                {...props}
              />
            ))}
          </section>
        </PageContainer>
      </LandingBackgroundWrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
};
