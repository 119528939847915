import { HydrateWhenInViewport, PageContainer } from '@loveholidays/design-system';
import React, { FC, useMemo } from 'react';
import { useQuery } from 'urql';

import getSearchResultsRecommendedHotels from './getSearchResultsRecommendedHotels.gql';
import { Query } from '@AuroraTypes';
import HolidayCollectionComponent from '@Components/LayoutComponents/HolidayCollectionComponent';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export const SearchResultsRecommendedHotelsContent: FC = () => {
  const searchSelection = useSearchSelectionStore(
    // this should not make request when the location has changed
    ({ filters: { location, ...filters }, ...state }) => ({
      boardBasis: state.boardBasis,
      cancellationPolicy: state.cancellationPolicy,
      date: state.date,
      departureAirports: state.departureAirports,
      flexibility: state.flexibility,
      masterId: state.masterId,
      masterIds: state.masterIds,
      pinnedMasterIds: state.pinnedMasterIds,
      nights: state.nights,
      rooms: state.rooms,
      filters,
      sort: state.sort,
      source: state.source,
    }),
  );
  const [{ data }] = useQuery<Query>({
    query: getSearchResultsRecommendedHotels,
    variables: { searchSelection },
  });

  const recommendedHotels = data?.User?.recommendedHotels;

  if (!recommendedHotels) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <PageContainer>
        <HolidayCollectionComponent
          {...recommendedHotels}
          internalLink
          sx={{
            marginTop: '3xl',
          }}
        />
      </PageContainer>
    ),
    [recommendedHotels],
  );
};

export const SearchResultsRecommendedHotels: FC = () => {
  return (
    // since we are using the HydrateWhenInViewport component, we need to give it some time to hydrate + fetch the data,
    // so we need to give it a rootMargin to trigger the hydration earlier than user reaches the component
    <HydrateWhenInViewport rootMargin="800px">
      <SearchResultsRecommendedHotelsContent />
    </HydrateWhenInViewport>
  );
};
